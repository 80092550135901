
function init() {
	// выходим, если функция уже выполнялась
	if (arguments.callee.done) return;

	// устанавливаем флаг, чтобы функция не исполнялась дважды
	arguments.callee.done = true;

	$('.main').show();
};

window.onload = init;

// new WOW().init();

$(function(){

	$('.selectpicker').selectpicker();

	$('.slider').slick({
		speed: 1000,
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: false,
		dots: true
	});

	$('.partners__slider').slick({
		slidesToShow: 4,
		autoplay: true,
		arrows: false,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('.certificates__slider').slick({
		slidesToShow: 4,
		autoplay: true,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('.slider--for').slick({
		autoplay: true,
	    slidesToShow: 1,
	    slidesToScroll: 1,
	    arrows: false,
	    fade: true,
	    asNavFor: '.slider--nav'
	});
	$('.slider--nav').slick({
		autoplay: true,
		autoplaySpeed: 2800,
	    slidesToShow: 3,
	    slidesToScroll: 1,
	    asNavFor: '.slider--for',
	    dots: false,
	    focusOnSelect: true,
	    arrows: true
	});

	$('.search--btn').on('click', function(){
		$('.search').fadeToggle();
		$('.search .close').on('click', function(){
			$(this).parents('.search').fadeOut();
			return false;
		});
		$('.search--close').on('click', function(){
			$(this).parents('.search').fadeOut();
			return false;
		});
		return false;
	});

	$('.arrow--up').bind('click.smoothscroll',function (e) {
		e.preventDefault();
		var target = this.hash,
		$target = $(target);

		$('html, body').stop().animate({
		 	'scrollTop': $target.offset().top
		 	}, 500, 'swing', function () {
		 	window.location.hash = target;
		});
	});
    $(window).scroll(function(){
        var bo = $(this).scrollTop();
        var a = $(".arrow--up").css('opacity')
		if ( bo >= 800 && a == 0) {$(".arrow--up").stop().animate({'opacity':'1'},500)};
        if ( bo < 800 && a == 1) {$(".arrow--up").stop().animate({'opacity':'0'},500)};
    });

    $('.phones--btn').on('click', function(){
		if($('header .phones').is(':visible')) {
			$('header .phones').slideUp().removeClass('open');
		} else {
			$('header .phones').slideDown().addClass('open');
		}
		$(document).bind("touchstart",function(e) {
		    if (!$(e.target).closest("header .phones.open").length) {
		        $('header .phones').slideUp().removeClass('open');
		    }
		    e.stopPropagation();
		});
		return false;
	});	

	$('.navbar-toggle').on('click', function(){
		$(this).toggleClass('open');
	});

	$('.middle .navbar-toggle').on('click', function(){
		$('.middle .navbar').fadeToggle(500);
		$('.middle .navbar--close').on('click', function(){
			$(this).parent().fadeOut();
			$('.middle .navbar-toggle').removeClass('open');
		});
	});

	$('.sidebar__btn').on('click', function(){
		$(this).parent().toggleClass('open');
		$(this).toggleClass('active');
		return false;
	});

	$('.input--phone').mask('+7 (999) 999-9999');

    var width = $(window).width();
    if (width > 1170){
        $('.nav .submenu > a').on('mouseover', function(){
            $(this).next('ul').slideDown(200);
        });  
     } else{
        $('.nav .submenu > a').on('click', function(){
            $(this).next('ul').slideToggle();
            return false;
        });  
    }

    $('.nav .submenu').on('mouseleave', function(){
        $(this).children('ul').slideUp(200);
    });

});